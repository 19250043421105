.crypto-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--dark-bg);
    min-height: 100vh;
    color: var(--text-primary);
}

[data-theme="light"] .crypto-detail-container {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
    color: var(--text-primary);
}

.crypto-detail-header {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem;
    background: rgba(13, 16, 45, 0.95);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

[data-theme="light"] .crypto-detail-header {
    background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

.crypto-detail-logo {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.crypto-detail-header h1 {
    font-size: 2.5rem;
    margin: 0;
    color: var(--text-primary);
}

[data-theme="light"] .crypto-detail-header h1 {
    color: #2c3e50;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.crypto-detail-header .symbol {
    display: block;
    color: var(--accent-color);
    font-size: 1.2rem;
    margin: 0.5rem 0;
}

.rating-large {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
}

.rating-large .stars {
    font-size: 1.5rem;
}

.rating-large .rating-value {
    font-size: 1.2rem;
    color: #00aaff;
}

.crypto-detail-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.description-section,
.shariah-section,
.pros-cons-section,
.content-section {
    background: rgba(13, 16, 45, 0.95);
    border-radius: 15px;
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

[data-theme="light"] .description-section,
[data-theme="light"] .shariah-section,
[data-theme="light"] .pros-cons-section,
[data-theme="light"] .content-section {
    background: #ffffff;
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

.description-section h2,
.shariah-section h2,
.pros-cons-section h2,
.content-section h2 {
    color: var(--accent-color);
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.description-section h2::after,
.shariah-section h2::after,
.pros-cons-section h2::after,
.content-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), transparent);
    border-radius: 2px;
}

.description-section p {
    line-height: 1.6;
    color: var(--text-primary);
}

.pros-cons-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.pros, .cons {
    background: rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    border-radius: 10px;
}

[data-theme="light"] .pros,
[data-theme="light"] .cons {
    background: rgba(240, 247, 255, 0.5);
}

.pros ul, .cons ul {
    list-style: none;
    padding: 0;
}

.pros li, .cons li {
    margin: 0.5rem 0;
    padding: 0.8rem 1.2rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    position: relative;
    color: var(--text-primary);
}

[data-theme="light"] .pros li,
[data-theme="light"] .cons li {
    background: rgba(255, 255, 255, 0.5);
}

.pros li:before {
    content: "✓";
    color: #4CAF50;
    position: absolute;
    right: 0.8rem;
}

.cons li:before {
    content: "✕";
    color: #f44336;
    position: absolute;
    right: 0.8rem;
}

.full-content {
    line-height: 1.8;
    color: var(--text-primary);
    white-space: pre-wrap;
}

.shariah-section {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 2rem;
}

.shariah-section h2 {
    color: #00aaff;
    margin-bottom: 1rem;
}

.shariah-status {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0;
    text-align: center;
    width: auto;
    min-width: 150px;
}

.status-halal {
    background: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
    border: 1px solid #4CAF50;
}

.status-haram {
    background: rgba(244, 67, 54, 0.1);
    color: #f44336;
    border: 1px solid #f44336;
}

.status-suspicious {
    background: rgba(255, 152, 0, 0.1);
    color: #ff9800;
    border: 1px solid #ff9800;
}

[data-theme="light"] .status-halal {
    background: rgba(76, 175, 80, 0.05);
}

[data-theme="light"] .status-haram {
    background: rgba(244, 67, 54, 0.05);
}

[data-theme="light"] .status-suspicious {
    background: rgba(255, 152, 0, 0.05);
}

.shariah-reason {
    margin-top: 1.5rem;
}

.shariah-reason h3 {
    color: #888;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.shariah-reason p {
    line-height: 1.6;
    color: #fff;
}

@media (max-width: 768px) {
    .crypto-detail-container {
        padding: 1rem;
    }

    .pros-cons-section {
        grid-template-columns: 1fr;
    }

    .crypto-detail-header h1 {
        font-size: 2rem;
    }
} 