/* ✅ تحسين التصميم وتخفيف تأثير البلور المتداخل */
.reviews-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.reviews-title {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    position: relative;
    padding-bottom: 1rem;
    font-weight: 600;
}

.reviews-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), transparent);
    border-radius: 2px;
}

[data-theme="light"] .reviews-title {
    color: #2c3e50;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.search-container {
    max-width: 600px;
    margin: 0 auto 2rem;
    padding: 0 1rem;
}

.search-input {
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: white;
    transition: border-color 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: #00aaff;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 0 10px rgba(0, 170, 255, 0.2);
}

.search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.reviews-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem 0;
}

.review-card {
    background: rgba(13, 16, 45, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
    transition: all 0.3s ease;
    color: inherit;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

[data-theme="light"] .review-card {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

[data-theme="light"] .review-card:hover {
    border-color: rgba(0, 120, 255, 0.2);
    box-shadow: 0 8px 25px rgba(0, 120, 255, 0.08);
}

.review-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.crypto-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.review-title h2 {
    margin: 0;
    font-size: 1.4rem;
    color: #fff;
}

.symbol {
    color: #64748b;
    font-size: 0.9rem;
}

.review-body {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* مسافة بين العناصر داخل review-body */
}

/* Rating styles */
.rating {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.stars {
    display: flex;
    gap: 2px;
}

.star {
    color: #64748b;
    font-size: 1.2rem;
}

.star.filled {
    color: #fbbf24;
}

.rating-value {
    color: #94a3b8;
    font-size: 0.9rem;
}

.description {
    color: #94a3b8;
    margin-bottom: 20px;
    line-height: 1.6;
}

.features-section {
    display: flex;
    gap: 15px;
    margin-top: 15px;
    padding: 12px;
    background: rgba(13, 16, 45, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    justify-content: center;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

[data-theme="light"] .features-section {
    background: rgba(240, 247, 255, 0.5);
}

.pros, .cons {
    flex: 1;
    max-width: 250px;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.03);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pros h3, .cons h3 {
    font-size: 1rem;
    margin-bottom: 8px;
    padding-bottom: 6px;
    border-bottom: 2px solid;
    text-align: center;
    width: 100%;
}

.pros h3 {
    color: #4CAF50;
    border-color: #4CAF50;
}

.cons h3 {
    color: #f44336;
    border-color: #f44336;
}

.pros ul, .cons ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.pros li, .cons li {
    padding: 6px 10px;
    margin-bottom: 6px;
    border-radius: 6px;
    font-size: 0.85rem;
    position: relative;
    padding-right: 25px;
    line-height: 1.3;
    text-align: right;
    width: 100%;
}

.pros li {
    background: rgba(76, 175, 80, 0.1);
    border: 1px solid rgba(76, 175, 80, 0.2);
}

.cons li {
    background: rgba(244, 67, 54, 0.1);
    border: 1px solid rgba(244, 67, 54, 0.2);
}

.pros li::before, .cons li::before {
    content: '';
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.pros li::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%234CAF50'%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z'/%3E%3C/svg%3E");
}

.cons li::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23f44336'%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E");
}

.read-more {
    background: transparent;
    border: 2px solid #00aaff;
    color: #00aaff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
    align-self: flex-start; /* للتأكد إن الزر في مكان واضح */
    margin-top: 1rem; /* مسافة فوق الزر */
    display: inline-block; /* للتأكد إن الزر بيظهر */
}

.read-more:hover {
    background: #00aaff;
    color: white;
}

.loading {
    color: white;
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
}

/* تحسينات التجاوب */
@media (max-width: 1024px) {
    .reviews-title {
        font-size: 2.2rem;
    }

    .reviews-grid {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 1.5rem;
    }

    .features-section {
        flex-direction: column;
        gap: 1.5rem;
    }
}

@media (max-width: 768px) {
    .reviews-container {
        padding: 0 0.75rem;
    }

    .reviews-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .search-container {
        margin-bottom: 1.5rem;
        padding: 0 0.5rem;
    }

    .search-input {
        font-size: 1rem;
        padding: 0.8rem;
    }

    .reviews-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .review-card {
        padding: 1.25rem;
    }

    .crypto-logo {
        width: 50px;
        height: 50px;
    }

    .review-title h2 {
        font-size: 1.3rem;
    }

    .symbol {
        font-size: 1rem;
    }

    .description {
        width: 90%;
    }

    .features-section {
        max-width: 100%;
        padding: 10px;
    }

    .pros, .cons {
        max-width: 100%;
        padding: 8px;
    }

    .read-more {
        padding: 0.5rem 0.8rem;
    }
}

@media (max-width: 480px) {
    .reviews-title {
        font-size: 1.8rem;
        padding-bottom: 0.75rem;
    }

    .reviews-title::after {
        width: 80px;
    }

    .search-input {
        padding: 0.75rem;
        font-size: 0.95rem;
    }

    .review-card {
        padding: 1rem;
    }

    .crypto-logo {
        width: 40px;
        height: 40px;
    }

    .review-title h2 {
        font-size: 1.2rem;
    }

    .symbol {
        font-size: 0.9rem;
    }

    .stars {
        font-size: 1rem;
    }

    .rating-value {
        font-size: 0.9rem;
    }

    .description {
        padding: 0.75rem;
        font-size: 0.9rem;
    }

    .pros h3, .cons h3 {
        font-size: 0.95rem;
    }

    .pros li, .cons li {
        font-size: 0.8rem;
        padding: 0.4rem 0.75rem;
    }

    .read-more {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }
}

@media (min-width: 1200px) {
    .features-section {
        max-width: 600px;
    }
    
    .pros, .cons {
        max-width: 250px;
    }
}