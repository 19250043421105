:root {
  --primary: #00ddeb; /* لون أساسي أكثر حيوية */
  --secondary: #ff007a; /* لون ثانوي جريء */
  --accent: #ffd700; /* لون تأكيد ذهبي حديث */
  --dark-bg: #0a0b1a; /* خلفية داكنة أعمق */
  --card-bg: #14152b; /* خلفية بطاقات أكثر نعومة */
  --text-primary: #f0f4ff; /* نص أبيض ناعم */
  --text-secondary: #a0a8d0; /* نص ثانوي أكثر حداثة */
  --glow: rgba(0, 221, 235, 0.7); /* توهج عصري */
  --shadow: 0 8px 32px rgba(0, 0, 0, 0.5); /* ظل أعمق */
}

body {
  background: var(--dark-bg);
  margin: 0;
  font-family: 'Almarai', 'Cairo', 'Tajawal', 'Poppins', sans-serif;
  overflow-x: hidden;
  color: var(--text-primary);
}

.home-container {
  max-width: 1500px; /* توسيع الحاوية لمظهر أكثر اتساعاً */
  margin: 0 auto;
  padding: 3rem;
  background: linear-gradient(135deg, var(--dark-bg), rgba(20, 21, 43, 0.95));
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

/* تحديث تأثير الشبكة */
.crypto-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    linear-gradient(rgba(0, 221, 235, 0.02) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 221, 235, 0.02) 1px, transparent 1px);
  background-size: 50px 50px; /* شبكة أكبر لمظهر أنيق */
  z-index: 0;
  pointer-events: none;
}

/* تحسين النقاط المتحركة */
.floating-coins {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.coin {
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--primary);
  border-radius: 50%;
  filter: blur(1px);
  box-shadow: 0 0 15px var(--glow);
  opacity: 0.6;
  animation: float 15s infinite ease-in-out;
}

.coin:nth-child(2n) {
  background: var(--secondary);
  animation-duration: 20s;
}

.coin:nth-child(3n) {
  background: var(--accent);
  animation-duration: 25s;
}

@keyframes float {
  0% {
    transform: translateY(100vh) scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.6;
  }
  100% {
    transform: translateY(-50px) translateX(calc(sin(var(--i, 0) * 60deg) * 300px)) scale(1);
    opacity: 0;
  }
}

/* قسم الترحيب */
/* قسم الترحيب */
.hero-section {
  text-align: center;
  padding: 5rem 3rem;
  margin-bottom: 3rem;
  background: linear-gradient(145deg, rgba(255, 255, 255, 0.02), rgba(107, 70, 193, 0.15)); /* تغيير اللون إلى أرجواني غامق */
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: var(--shadow);
  backdrop-filter: blur(15px); /* تأثير زجاجي حديث */
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.hero-section:hover {
  transform: translateY(-5px);
}

.hero-section::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(107, 70, 193, 0.3) 0%, transparent 60%); /* تطابق اللون مع الخلفية */
  animation: rotate 30s linear infinite;
  z-index: -1;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hero-section h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(90deg, var(--primary), var(--secondary), var(--accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  animation: gradient-shift 5s ease infinite;
  font-weight: 900;
  letter-spacing: -1px;
  text-transform: uppercase;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.hero-description {
  font-size: 1.5rem;
  line-height: 1.8;
  max-width: 900px;
  margin: 0 auto 2rem;
  color: var(--text-secondary);
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* أزرار الدعوة للعمل */
.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.primary-btn {
  padding: 1rem 3rem;
  background: linear-gradient(135deg, var(--primary), var(--secondary));
  color: white;
  border: none;
  border-radius: 60px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.4s ease;
  box-shadow: 0 0 20px var(--glow);
  position: relative;
  overflow: hidden;
}

.primary-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px var(--glow);
}

.primary-btn::after {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transform: rotate(30deg);
  transition: 0.6s;
}

.primary-btn:hover::after {
  left: 150%;
  transition: 0.6s;
}

/* أقسام المراجعات */
.reviews-section {
  margin-bottom: 4rem;
  padding: 3rem;
  background: linear-gradient(135deg, rgba(20, 21, 43, 0.95), rgba(255, 255, 255, 0.03));
  border-radius: 30px;
  box-shadow: var(--shadow);
  position: relative;
  z-index: 1;
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.section-header h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, var(--primary), var(--accent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 5px;
  background: var(--primary);
  border-radius: 2px;
  box-shadow: 0 0 10px var(--glow);
}

.section-header p {
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.view-all {
  display: inline-block;
  padding: 0.7rem 2rem;
  background: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
  text-decoration: none;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.view-all::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(45deg, var(--primary), var(--secondary));
  transition: all 0.3s ease;
  z-index: -1;
}

.view-all:hover {
  color: white;
}

.view-all:hover::before {
  width: 100%;
}

/* شبكة المراجعات */
.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 3rem;
}

.review-card {
  background: var(--card-bg);
  border-radius: 25px;
  padding: 2.5rem;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  position: relative;
  overflow: hidden;
  animation: fadeInUp 0.5s forwards;
  opacity: 0;
  transform: translateY(30px);
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.reviews-grid .review-card:nth-child(1) {
  animation-delay: 0.1s;
}

.reviews-grid .review-card:nth-child(2) {
  animation-delay: 0.2s;
}

.reviews-grid .review-card:nth-child(3) {
  animation-delay: 0.3s;
}

.review-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background: linear-gradient(90deg, var(--primary), var(--secondary), var(--accent));
  transition: height 0.3s ease;
}

.review-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 15px 40px rgba(0, 221, 235, 0.3);
  background: linear-gradient(145deg, var(--card-bg), rgba(20, 21, 43, 0.9));
}

.review-card:hover::before {
  height: 8px;
}

.card-header {
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
}

.crypto-icon-bg {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  height: 90px;
  background: linear-gradient(145deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.05));
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.review-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  filter: drop-shadow(0 0 15px var(--glow));
  transition: all 0.3s ease;
}

.review-card:hover .review-image {
  transform: scale(1.15);
  filter: drop-shadow(0 0 20px var(--glow));
}

.card-header h3 {
  margin: 0;
  font-size: 1.75rem;
  color: white;
  font-weight: 700;
}

.symbol {
  color: var(--secondary);
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0.25rem;
  display: inline-block;
}

.review-rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.stars-container {
  display: flex;
  gap: 0.2rem;
}

.star-icon {
  color: rgba(255, 215, 0, 0.3);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.star-icon.filled {
  color: #ffd700;
}

.rating-number {
  color: var(--primary);
  font-weight: 600;
  margin-right: 0.5rem;
}

.rating {
  text-align: center;
  margin: 1.5rem 0;
  font-size: 1.3rem;
}

.star {
  color: rgba(255, 215, 0, 0.2);
  margin: 0 2px;
  transition: all 0.3s ease;
}

.star.filled {
  color: #ffd700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.review-card:hover .star.filled {
  animation: pulse-star 1s infinite;
}

@keyframes pulse-star {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }
  50% {
    text-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
  }
}

.description {
  margin: 1.5rem 0;
  line-height: 1.7;
  color: var(--text-secondary);
  flex-grow: 1;
}

.company-features {
  margin: 1.5rem 0;
  background: rgba(0, 0, 0, 0.2);
  padding: 1.5rem;
  border-radius: 12px;
}

.feature {
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0;
  font-size: 1rem;
  position: relative;
}

.feature::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
}

.feature:last-child::after {
  display: none;
}

.feature-label {
  color: var(--text-secondary);
}

.feature-value {
  color: var(--primary);
  font-weight: 600;
}

.read-more {
  display: inline-block;
  width: 100%;
  padding: 1.2rem;
  text-align: center;
  background: linear-gradient(135deg, rgba(0, 221, 235, 0.1), rgba(255, 0, 122, 0.1));
  color: var(--primary);
  text-decoration: none;
  border-radius: 60px;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 15px var(--glow);
  z-index: 1;
}

.read-more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(135deg, var(--primary), var(--secondary));
  transition: all 0.3s ease;
  z-index: -1;
}

.read-more:hover {
  color: white;
}

.read-more:hover::before {
  width: 100%;
}

/* تأثير التمويج للأسعار */
.price-wave {
  position: relative;
  height: 50px;
  margin: 1rem 0;
  overflow: hidden;
}

.wave-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  border-bottom: 2px solid var(--primary);
  animation: wave 5s infinite ease-in-out;
}

@keyframes wave {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0.5);
  }
}

/* قسم العملات المميزة */
.featured-coins {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 2rem 0;
  gap: 1rem;
}

.coin-badge {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.coin-badge img {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.coin-badge:hover {
  transform: translateY(-3px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* تحسينات للأجهزة المحمولة */
@media (max-width: 1200px) {
  .home-container {
    padding: 1.5rem;
  }
  
  .hero-section h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .home-container {
    padding: 1rem;
  }

  .hero-section {
    padding: 3rem 1rem;
  }

  .hero-section h1 {
    font-size: 2.5rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }

  .section-header h2 {
    font-size: 2rem;
  }

  .reviews-section {
    padding: 2rem;
  }

  .reviews-grid {
    grid-template-columns: 1fr;
  }
  
  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }
  
  .primary-btn, .view-all {
    width: 100%;
  }

  .review-card {
    padding: 1.5rem;
  }
}

/* لإضافة النقاط المتحركة (عملات) في الخلفية */
.js-coins-generator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

/* كود لتأثير الوميض عند تحديث الأسعار */
.price-update {
  animation: flash-green 1s;
}

.price-down {
  animation: flash-red 1s;
}

@keyframes flash-green {
  0%, 100% {
    color: var(--text-primary);
  }
  50% {
    color: #00ff88;
  }
}

@keyframes flash-red {
  0%, 100% {
    color: var(--text-primary);
  }
  50% {
    color: #ff3366;
  }
}

/* إضافة الرمز المائي للعملات الرقمية */
.crypto-watermark {
  position: fixed;
  bottom: 5%;
  right: 5%;
  font-size: 15rem;
  opacity: 0.02;
  color: white;
  transform: rotate(-15deg);
  pointer-events: none;
  z-index: 0;
}

/* تنسيق النجوم في قسم أحدث المراجعات */
.latest-review .rating,
.review-card .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.latest-review .stars,
.review-card .stars {
  display: flex;
  gap: 0.2rem;
}

.latest-review .star,
.review-card .star {
  color: rgba(255, 215, 0, 0.3);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.latest-review .star.filled,
.review-card .star.filled {
  color: #ffd700;
}

.latest-review .rating-value,
.review-card .rating-value {
  color: var(--primary);
  font-weight: 600;
  margin-right: 0.5rem;
}

/* تنسيق النجوم في المراجعات المميزة */
.featured-review .rating {
  justify-content: center;
}
/* تنسيق الـ Loader */
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--dark-bg, #0a0b1a);
  color: var(--text-primary, #f0f4ff);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid var(--primary, #00ddeb);
  border-top: 5px solid transparent;
  border-radius: 50%;
}

.loader-container p {
  margin-top: 1rem;
  font-size: 1.2rem;
}

/* تحسينات إضافية */
.reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 3rem;
  position: relative;
}