/* إعادة تعيين الهوامش والحشو الافتراضي */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Almarai', sans-serif;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* استيراد خط Almarai */
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

/* تحسين مظهر العناوين */
h1, h2, h3, h4, h5, h6 {
    font-weight: 800;
    letter-spacing: 0;
    color: var(--text-primary);
}

/* تحسين مظهر النصوص */
p, span, div {
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0;
    word-spacing: 0.1em;
    color: var(--text-secondary);
}

/* تحسين الخط للأزرار والروابط */
button, a, nav a, .user-button, .dropdown-menu button {
    font-weight: 700;
    letter-spacing: 0;
}

:root {
    /* الوضع الليلي (الافتراضي) */
    --bg-primary: #0d102d;
    --bg-secondary: rgba(255, 255, 255, 0.05);
    --text-primary: #ffffff;
    --text-secondary: rgba(255, 255, 255, 0.7);
    --accent-color: #00aaff;
    --border-color: rgba(255, 255, 255, 0.1);
    --card-bg: rgba(255, 255, 255, 0.07);
    --hover-bg: rgba(255, 255, 255, 0.12);
    --shadow-color: rgba(0, 0, 0, 0.3);
    --input-bg: rgba(255, 255, 255, 0.05);
    --input-border: rgba(255, 255, 255, 0.1);
    --input-text: #ffffff;
    --nav-bg: rgba(13, 16, 45, 0.95);
    --dark-bg: #0d102d;
    --gradient-start: rgba(20, 21, 43, 0.95);
    --gradient-end: rgba(13, 16, 45, 0.95);
    --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    --header-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    --button-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

[data-theme="light"] {
    --bg-primary: #ffffff;
    --bg-secondary: #f8faff;
    --text-primary: #2d3748;
    --text-secondary: #4a5568;
    --accent-color: #0078ff;
    --border-color: #e2e8f0;
    --card-bg: #ffffff;
    --hover-bg: #f0f7ff;
    --shadow-color: rgba(0, 120, 255, 0.08);
    --input-bg: #ffffff;
    --input-border: #e2e8f0;
    --input-text: #2d3748;
    --nav-bg: rgba(255, 255, 255, 0.98);
    --dark-bg: #ffffff;
    --gradient-start: #ffffff;
    --gradient-end: #f8faff;
    --card-shadow: 0 4px 10px rgba(0, 120, 255, 0.05);
    --header-shadow: 0 2px 10px rgba(0, 120, 255, 0.05);
    --button-shadow: 0 2px 4px rgba(0, 120, 255, 0.1);
}

body {
    font-family: 'Cairo', sans-serif;
    background-color: var(--bg-primary);
    color: var(--text-primary);
    line-height: 1.6;
    overflow-x: hidden;
}

.app-container {
    min-height: 100vh;
    max-width: 1500px;
    margin: 0 auto;
    padding: 1.5rem;
    background: var(--dark-bg);
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.5s ease-out;
    position: relative;
    z-index: 1;
}

[data-theme="light"] .app-container {
    background: linear-gradient(135deg, #e6f0ff 0%, #ffffff 100%);
}

.app-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    z-index: -1;
}

[data-theme="light"] .app-container::before {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
}

.main-content {
    flex: 1;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    position: relative;
    z-index: 1;
    animation: slideUp 0.6s ease-out;
    margin-top: 120px;
}

[data-theme="light"] .main-content {
    background: transparent;
}

/* تنسيق الهيدر والناف بار */
.app-header {
    background: var(--nav-bg);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 1rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: 1px solid var(--border-color);
    transition: all 0.3s ease;
}

.app-header.scrolled {
    padding: 0.8rem 0;
    box-shadow: 0 5px 20px var(--shadow-color);
}

/* تحسين العنوان */
.app-title {
    text-align: left;
    margin: 0 0 1rem 2rem;
    padding: 0;
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: 2px;
    background: linear-gradient(45deg, var(--accent-color), #00b7ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease;
    animation: float 3s ease-in-out infinite;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0px); }
}

.app-title:hover {
    transform: scale(1.02);
    background: linear-gradient(45deg, #00b7ff, var(--accent-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-title::after {
    display: none;
}

nav {
    max-width: 1400px;
    margin: -0.5rem auto 0;
    padding: 0 2rem;
    position: relative;
}

nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 3rem;
    padding: 0;
    margin: 0;
}

nav li {
    position: relative;
}

nav a {
    color: var(--text-primary);
    text-decoration: none;
    padding: 0.75rem 0;
    font-weight: 500;
    letter-spacing: 0.5px;
    transition: color 0.3s ease;
    position: relative;
}

nav a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background: var(--accent-color);
    transition: width 0.3s ease;
}

nav a:hover {
    color: var(--accent-color);
}

nav a:hover::after {
    width: 100%;
}

.mobile-menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--text-primary);
    font-size: 1.8rem;
    cursor: pointer;
    padding: 0.75rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    z-index: 1001;
    transition: color 0.3s ease;
}

.mobile-menu-toggle:hover {
    color: var(--accent-color);
}

.mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.mobile-menu-overlay.active {
    display: block;
    opacity: 1;
}

.user-menu {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 120%;
    right: 0;
    background: var(--card-bg);
    border: 1px solid var(--border-color);
    box-shadow: var(--card-shadow);
    border-radius: 8px;
    padding: 0.5rem;
    min-width: 200px;
    display: none;
    margin-top: 5px;
    z-index: 1000;
}

.user-menu.active .dropdown-menu {
    display: block !important;
}

.dropdown-menu button {
    width: 100%;
    padding: 1rem;
    text-align: right;
    cursor: pointer;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: inherit;
    color: var(--text-primary);
    background: none;
    border: none;
    transition: color 0.3s ease;
}

.dropdown-menu button:hover {
    color: var(--accent-color);
}

/* تحسينات التجاوب */
@media (max-width: 1024px) {
    .app-title {
        font-size: 2.2rem;
        margin-left: 1.5rem;
    }

    nav ul {
        gap: 2rem;
    }

    .main-content {
        padding: 1.5rem;
    }
}

@media (max-width: 768px) {
    .app-title {
        font-size: 1.8rem;
        margin-left: 1rem;
        padding-right: 60px;
    }

    .mobile-menu-toggle {
        display: block;
    }

    nav {
        padding: 0;
    }

    nav ul {
        flex-direction: column;
        position: fixed;
        top: 0;
        right: -100%;
        width: 80%;
        max-width: 300px;
        height: 100vh;
        background: var(--nav-bg);
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        padding: 5rem 2rem 2rem;
        transition: right 0.3s ease;
        box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
        z-index: 1000;
        align-items: flex-start;
        gap: 2rem;
    }

    nav.active ul {
        right: 0;
    }

    nav li {
        width: 100%;
    }

    nav a {
        padding: 1rem 0;
        font-size: 1.1rem;
    }

    .dropdown-menu {
        position: static;
        width: 100%;
        margin-top: 0.5rem;
        padding: 0;
        box-shadow: none;
        border: none;
        background: var(--input-bg);
    }

    .main-content {
        margin-top: 90px;
        padding: 1rem;
    }

    .app-header {
        padding: 0.8rem 0;
    }
}

@media (max-width: 480px) {
    .app-title {
        font-size: 1.5rem;
        padding-right: 50px;
    }

    nav ul {
        width: 100%;
        padding: 4rem 1.5rem 1rem;
    }

    nav a {
        font-size: 1rem;
    }

    .main-content {
        padding: 0.75rem;
    }
}

/* بقية التنسيقات */
a {
    color: var(--accent-color);
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    opacity: 0.8;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.reviews-section {
    margin: 3rem 0;
    background: var(--dark-bg);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: var(--card-shadow);
    border: 1px solid var(--border-color);
    position: relative;
    z-index: 2;
}

[data-theme="light"] .reviews-section {
    background: linear-gradient(135deg, #e6f0ff 0%, #ffffff 100%);
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

.reviews-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    z-index: -1;
    border-radius: 15px;
}

[data-theme="light"] .reviews-section::before {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
}

.section-header {
    text-align: center;
    margin-bottom: 3rem;
    position: relative;
    z-index: 2;
}

.section-header h2 {
    color: var(--text-primary);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 700;
}

.section-header p {
    color: var(--text-secondary);
    font-size: 1.1rem;
    max-width: 700px;
    margin: 0 auto 1.5rem;
    line-height: 1.6;
}

.review-card {
    background: var(--dark-bg);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: var(--card-shadow);
    transition: all 0.3s ease;
    border: 1px solid var(--border-color);
    position: relative;
    overflow: hidden;
}

[data-theme="light"] .review-card {
    background: linear-gradient(135deg, #e6f0ff 0%, #ffffff 100%);
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

[data-theme="light"] .review-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 120, 255, 0.08);
    border-color: rgba(0, 120, 255, 0.2);
}

.review-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
    z-index: -1;
    border-radius: 15px;
}

[data-theme="light"] .review-card::before {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
}

.btn {
    background: var(--accent-color);
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    font-weight: 600;
    box-shadow: var(--button-shadow);
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 120, 255, 0.15);
}

input, textarea, select {
    background: var(--input-bg);
    border: 1px solid var(--input-border);
    color: var(--input-text);
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;
    width: 100%;
}

input:focus, textarea:focus, select:focus {
    border-color: var(--accent-color);
    box-shadow: 0 0 0 3px rgba(0, 120, 255, 0.1);
    outline: none;
}