/* استيراد خط Almarai */
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

/* إضافة الخط إلى الشريط العلوي */
.app-header, 
.app-title, 
nav ul li a, 
.user-button, 
.dropdown-menu button {
    font-family: 'Almarai', sans-serif;
}

/* المتغيرات للألوان */
:root {
    --primary: #00ddeb;
    --secondary: #ff007a;
    --accent: #ffd700;
    --dark-bg: #0a0b1a;
    --card-bg: #14152b;
    --text-primary: #f0f4ff;
    --text-secondary: #a0a8d0;
    --glow: rgba(0, 221, 235, 0.7);
    --shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
}

/* الـ Header الرئيسي */
.app-header {
    background: rgba(10, 11, 26, 0.9);
    backdrop-filter: blur(15px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem 2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* العنوان */
.app-title {
    margin: 0;
    font-size: 2rem;
    font-weight: 800;
}

.app-title a {
    color: var(--primary);
    text-decoration: none;
    background: linear-gradient(90deg, var(--primary), var(--secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease;
}

.app-title a:hover {
    filter: brightness(1.2);
}

/* القائمة الرئيسية */
nav {
    display: block;
}

nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 0;
    padding: 0;
}

nav ul li {
    position: relative;
}

nav ul li a {
    color: var(--text-primary);
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    transition: all 0.3s ease;
    position: relative;
}

nav ul li a:hover {
    color: var(--primary);
}

nav ul li a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background: linear-gradient(90deg, var(--primary), var(--secondary));
    transition: width 0.3s ease, left 0.3s ease;
}

nav ul li a:hover::after {
    width: 100%;
    left: 0;
}

/* قائمة المستخدم */
.user-menu {
    position: relative;
}

.user-button {
    background: linear-gradient(135deg, rgba(0, 221, 235, 0.1), rgba(255, 0, 122, 0.1));
    color: var(--text-primary);
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 0 10px var(--glow);
}

.user-button:hover {
    background: linear-gradient(135deg, var(--primary), var(--secondary));
    color: white;
}

.arrow {
    font-size: 0.8rem;
}

/* الـ Dropdown Menu */
.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: rgba(20, 21, 43, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: var(--shadow);
    min-width: 150px;
    padding: 0.5rem 0;
    z-index: 1000;
    display: none;
}

.user-menu.active .dropdown-menu {
    display: block;
}

.dropdown-menu button {
    width: 100%;
    background: transparent;
    border: none;
    color: var(--text-primary);
    padding: 0.8rem 1.5rem;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
}

.dropdown-menu button:hover {
    background: rgba(255, 255, 255, 0.1);
    color: var(--primary);
}

/* زر القائمة في الموبايل */
.mobile-menu-toggle {
    display: none;
    background: transparent;
    border: none;
    color: var(--text-primary);
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.mobile-menu-toggle:hover {
    color: var(--primary);
}

/* الـ Overlay للموبايل */
.mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 999;
}

.mobile-menu-overlay.active {
    display: block;
}

/* تنسيقات الموبايل */
@media (max-width: 768px) {
    .mobile-menu-toggle {
        display: block;
    }

    .mobile-menu-overlay {
        display: none;
    }

    .mobile-menu-overlay.active {
        display: block;
    }

    nav {
        position: fixed;
        top: 0;
        right: -250px;
        height: 100%;
        width: 250px;
        background: rgba(10, 11, 26, 0.95);
        backdrop-filter: blur(15px);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        z-index: 1000;
        transition: right 0.4s ease-out;
    }

    nav.active {
        right: 0;
    }

    nav ul {
        flex-direction: column;
        padding: 3rem 1rem;
        gap: 1.5rem;
    }

    nav ul li a {
        font-size: 1.2rem;
        padding: 0.8rem;
        display: block;
    }

    .user-menu {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .user-button {
        width: 80%;
        justify-content: center;
    }

    .dropdown-menu {
        position: static;
        top: auto;
        right: auto;
        width: 80%;
        margin: 0.5rem auto;
        background: rgba(20, 21, 43, 0.95);
        backdrop-filter: blur(10px);
        border-radius: 15px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: var(--shadow);
        padding: 0.5rem 0;
        z-index: 1001;
        display: none;
    }

    .user-menu.active .dropdown-menu {
        display: block;
    }

    .dropdown-menu button {
        padding: 0.8rem 1rem;
        text-align: center;
        font-weight: 600;
    }
}

/* تحسينات إضافية للشاشات الصغيرة جدًا */
@media (max-width: 480px) {
    .app-header {
        padding: 1rem;
    }

    .app-title {
        font-size: 1.5rem;
    }

    .mobile-menu-toggle {
        font-size: 1.3rem;
    }

    nav {
        width: 200px;
    }

    .user-button {
        width: 90%;
    }

    .dropdown-menu {
        width: 90%;
    }
}

.theme-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.theme-toggle:hover {
    background-color: var(--text-secondary);
    transform: scale(1.1);
}

.theme-toggle:active {
    transform: scale(0.95);
}