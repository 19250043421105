.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.login-modal {
    background: #0d102d;
    padding: 2rem;
    border-radius: 15px;
    width: 90%;
    max-width: 400px;
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #888;
    font-size: 1.5rem;
    cursor: pointer;
}

.close-button:hover {
    color: white;
}

.login-modal h2 {
    color: #00aaff;
    margin-bottom: 1.5rem;
    text-align: center;
}

.error-message {
    background: rgba(244, 67, 54, 0.1);
    color: #f44336;
    padding: 0.8rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
}

/* إضافة تنسيقات للقائمة المنسدلة في App.css */
.user-menu {
    position: relative;
}

.user-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.arrow {
    font-size: 0.8rem;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: #1a1f4d;
    border-radius: 5px;
    padding: 0.5rem;
    display: none;
    min-width: 150px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
}

.user-menu:hover .dropdown-menu {
    display: block;
}

.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    border: none;
    color: white;
    text-align: right;
    cursor: pointer;
}

.dropdown-menu button:hover {
    background: rgba(255, 255, 255, 0.1);
    color: #00aaff;
}

/* أضف هذه التنسيقات إلى الملف الحالي */

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    color: #fff;
    margin-bottom: 0.5rem;
}

.form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.05);
    color: white;
    font-size: 1rem;
}

.form-group input:focus {
    outline: none;
    border-color: #00aaff;
}

.form-info {
    text-align: center;
    color: #888;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.submit-button {
    width: 100%;
    padding: 1rem;
    background: #00aaff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-button:hover {
    background: #0088cc;
}

.submit-button:disabled {
    background: #666;
    cursor: not-allowed;
}

/* تحسين مظهر Placeholder */
::placeholder {
    color: rgba(255, 255, 255, 0.3);
}

/* يحتوي على:
- تنسيق نافذة تسجيل الدخول
- تنسيق النماذج
- تنسيق الأزرار
- تنسيق رسائل الخطأ
*/ 