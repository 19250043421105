.section-title {
    color: var(--text-primary);
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1.5rem;
    position: relative;
    font-weight: 600;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), transparent);
    border-radius: 2px;
}

[data-theme="light"] .section-title {
    color: #2c3e50;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

[data-theme="light"] .section-title .icon {
    color: var(--accent-color);
    filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.1));
}

.icon {
    font-size: 2.5rem;
    margin-left: 0.5rem;
}

.companies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem 0;
}

.company-card {
    transition: transform 0.3s ease;
}

.company-card:hover {
    transform: translateY(-5px);
}

.card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

.company-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.card-header h3 {
    color: var(--text-primary);
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
}

.rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.rating-value {
    color: var(--accent-color);
    font-size: 1.5rem;
    font-weight: bold;
}

.stars {
    color: #ffd700;
    font-size: 1.2rem;
}

.star {
    color: rgba(255, 215, 0, 0.3);
}

.star.filled {
    color: #ffd700;
}

.company-features {
    margin: 1.5rem 0;
}

.feature {
    color: var(--text-primary);
    margin: 0.8rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.check {
    color: var(--accent-color);
    font-weight: bold;
}

.read-more {
    width: 100%;
    background: transparent;
    border: 2px solid var(--accent-color);
    color: var(--accent-color);
    padding: 0.8rem;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
    font-weight: bold;
}

.read-more:hover {
    background: var(--accent-color);
    color: white;
}

.loading {
    color: var(--text-primary);
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
}

.search-container {
    max-width: 600px;
    margin: 0 auto 2rem;
    padding: 0 1rem;
}

.search-input {
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
    background: var(--input-bg);
    border: 1px solid var(--input-border);
    border-radius: 8px;
    color: var(--input-text);
    transition: border-color 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: var(--accent-color);
    background: var(--hover-bg);
    box-shadow: 0 0 10px var(--shadow-color);
}

.search-input::placeholder {
    color: var(--text-secondary);
}

/* تحسينات التجاوب */
@media (max-width: 1024px) {
    .section-title {
        font-size: 2.2rem;
        padding: 1.25rem;
    }

    .icon {
        font-size: 2.2rem;
    }

    .companies-grid {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 1.5rem;
    }

    .card-header h3 {
        font-size: 1.4rem;
    }

    .rating-value {
        font-size: 1.4rem;
    }
}

@media (max-width: 768px) {
    .section-title {
        font-size: 1.8rem;
        padding: 1rem;
        gap: 0.75rem;
    }

    .section-title::after {
        width: 80px;
    }

    .icon {
        font-size: 1.8rem;
    }

    .companies-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .company-card {
        padding: 1rem;
    }

    .company-logo {
        width: 50px;
        height: 50px;
    }

    .card-header h3 {
        font-size: 1.3rem;
    }

    .rating-value {
        font-size: 1.3rem;
    }

    .stars {
        font-size: 1rem;
    }

    .company-features {
        margin: 1rem 0;
    }

    .feature {
        font-size: 0.9rem;
        margin: 0.6rem 0;
    }

    .read-more {
        padding: 0.6rem;
        font-size: 0.95rem;
    }

    .search-container {
        margin-bottom: 1.5rem;
        padding: 0 0.75rem;
    }

    .search-input {
        padding: 0.8rem;
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.5rem;
        padding: 0.75rem;
        gap: 0.5rem;
    }

    .section-title::after {
        width: 60px;
    }

    .icon {
        font-size: 1.5rem;
        margin-left: 0.3rem;
    }

    .company-card {
        padding: 0.75rem;
    }

    .company-logo {
        width: 40px;
        height: 40px;
    }

    .card-header {
        gap: 0.75rem;
        margin-bottom: 1rem;
    }

    .card-header h3 {
        font-size: 1.2rem;
    }

    .rating-value {
        font-size: 1.2rem;
    }

    .stars {
        font-size: 0.9rem;
    }

    .feature {
        font-size: 0.85rem;
        margin: 0.5rem 0;
    }

    .read-more {
        padding: 0.5rem;
        font-size: 0.9rem;
    }

    .search-input {
        padding: 0.75rem;
        font-size: 0.95rem;
    }

    .loading {
        font-size: 1rem;
        padding: 1.5rem;
    }
}