.crypto-review-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.review-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

label {
    font-weight: bold;
    color: #ffffff;
    font-size: 1.1rem;
}

input, textarea, select {
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    font-size: 1rem;
    color: #ffffff;
    transition: all 0.3s ease;
}

input:focus, textarea:focus, select:focus {
    outline: none;
    border-color: #00aaff;
    background: rgba(255, 255, 255, 0.1);
}

textarea {
    resize: vertical;
    min-height: 100px;
}

.list-input {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.add-button {
    background: #00aaff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    align-self: flex-start;
    transition: all 0.3s ease;
}

.add-button:hover {
    background: #0088cc;
    transform: translateY(-2px);
}

.submit-button {
    background: #00aaff;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
    margin-top: 1rem;
    transition: all 0.3s ease;
}

.submit-button:hover {
    background: #0088cc;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background: #666666;
    cursor: not-allowed;
    transform: none;
}

h2 {
    color: #ffffff;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
}

/* تحسين المظهر للأجهزة المحمولة */
@media (max-width: 768px) {
    .crypto-review-container {
        margin: 1rem;
        padding: 1rem;
    }
}

/* تنسيق القوائم */
ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #ffffff;
}

li {
    background: rgba(255, 255, 255, 0.05);
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* تنسيق الأخطاء */
.error-message {
    color: #ff4444;
    background: rgba(255, 68, 68, 0.1);
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 0.5rem;
}

/* تنسيق رسائل النجاح */
.success-message {
    color: #00ff88;
    background: rgba(0, 255, 136, 0.1);
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 0.5rem;
}

/* تحسين مظهر placeholder */
::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

/* إضافة الأنماط الجديدة */
.review-type-selector {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.type-btn {
    padding: 0.5rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.type-btn.active {
    background: #4CAF50;
    color: white;
    border-color: #4CAF50;
}

.current-reviews {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid #ddd;
}

.reviews-tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.tab-btn {
    padding: 0.5rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

.tab-btn.active {
    background: #4CAF50;
    color: white;
    border-color: #4CAF50;
}

.reviews-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-item {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1.5rem;
}

.review-info {
    flex: 1;
}

.review-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.review-title {
    font-size: 1.4rem;
    margin: 0;
    color: #fff;
}

.review-date {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
}

/* تنسيق المميزات والعيوب */
.pros-cons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 1.5rem 0;
}

.pros-section, .cons-section {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 1.2rem;
}

.pros-section h4, .cons-section h4 {
    margin: 0 0 1rem 0;
    color: #fff;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.pros-section h4::before {
    content: '✓';
    color: #4CAF50;
}

.cons-section h4::before {
    content: '✕';
    color: #f44336;
}

.pros-section ul, .cons-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.pros-section li, .cons-section li {
    padding: 0.5rem 0;
    color: rgba(255, 255, 255, 0.8);
    position: relative;
    padding-right: 1.2rem;
}

.pros-section li::before {
    content: '•';
    color: #4CAF50;
    position: absolute;
    right: 0;
}

.cons-section li::before {
    content: '•';
    color: #f44336;
    position: absolute;
    right: 0;
}

/* تنسيق أزرار التحكم */
.review-actions {
    display: flex;
    gap: 0.8rem;
    align-items: flex-start;
}

.edit-btn, .delete-btn {
    padding: 0.6rem 1.2rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
}

/* تحسينات للتجاوب */
@media (max-width: 768px) {
    .review-item {
        grid-template-columns: 1fr;
    }

    .pros-cons-container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .review-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .review-actions {
        justify-content: flex-end;
        width: 100%;
    }
}

/* إضافة أنماط للحكم الشرعي */
.shariah-status {
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-weight: bold;
    margin: 0.5rem 0;
}

.status-halal {
    background: rgba(76, 175, 80, 0.1);
    color: #4CAF50;
    border: 1px solid #4CAF50;
}

.status-haram {
    background: rgba(244, 67, 54, 0.1);
    color: #f44336;
    border: 1px solid #f44336;
}

.status-suspicious {
    background: rgba(255, 152, 0, 0.1);
    color: #ff9800;
    border: 1px solid #ff9800;
}

/* تحديث الخطوط في صفحة المراجعات */
.review-title {
    font-family: var(--font-primary);
    font-weight: 600;
    letter-spacing: 0.5px;
}

.review-content {
    font-family: var(--font-primary);
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.6;
}

.review-meta {
    font-family: var(--font-primary);
    font-weight: 500;
}

.review-rating {
    font-family: var(--font-primary);
    font-weight: 600;
}

/* يحتوي على:
- تنسيق نموذج الإضافة
- تنسيق الحقول والأزرار
- تنسيق رسائل الخطأ والنجاح
*/

/* تنسيق القوائم المنسدلة */
select, 
.type-btn,
.tab-btn {
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: rgba(255, 255, 255, 0.8) !important;
    padding: 0.8rem 1.2rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

select {
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    padding-right: 2.5rem;
}

select option {
    background: rgba(13, 16, 45, 0.95);
    color: white;
    padding: 0.8rem;
}

/* تحسين تأثير hover */
select:hover,
.type-btn:hover,
.tab-btn:hover {
    background: rgba(255, 255, 255, 0.15) !important;
    border-color: rgba(255, 255, 255, 0.2) !important;
}

/* تنسيق الأزرار النشطة */
.type-btn.active,
.tab-btn.active {
    background: rgba(0, 170, 255, 0.2) !important;
    border-color: rgba(0, 170, 255, 0.3) !important;
    color: #00aaff !important;
}

/* تنسيق حقل رمز العملة */
.symbol-input {
    background: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    color: rgba(255, 255, 255, 0.8) !important;
    padding: 0.8rem 1.2rem;
    border-radius: 8px;
    width: 100%;
    font-family: var(--font-primary);
    letter-spacing: 1px;
    transition: all 0.3s ease;
}

.symbol-input::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.symbol-input:focus {
    border-color: rgba(0, 170, 255, 0.3) !important;
    outline: none;
    background: rgba(255, 255, 255, 0.15) !important;
}

.input-hint {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin-top: 0.5rem;
    display: block;
} 