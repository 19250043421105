.company-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--dark-bg);
    min-height: 100vh;
    color: var(--text-primary);
}

[data-theme="light"] .company-detail-container {
    background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
    color: var(--text-primary);
}

.company-detail-header {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem;
    background: rgba(13, 16, 45, 0.95);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

[data-theme="light"] .company-detail-header {
    background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

.company-detail-header h1 {
    font-size: 2.5rem;
    margin: 0;
    color: var(--text-primary);
}

[data-theme="light"] .company-detail-header h1 {
    color: #2c3e50;
    text-shadow: 1px 1px 0px rgba(255, 255, 255, 0.5);
}

.company-detail-logo {
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.rating-large {
    text-align: center;
    margin: 2rem 0;
}

.rating-value {
    font-size: 2.5rem;
    color: #00aaff;
    font-family: var(--font-primary);
    font-weight: 700;
}

.stars {
    color: #ffd700;
    font-size: 1.8rem;
    margin-top: 0.5rem;
}

.company-detail-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.info-section, 
.features-section, 
.description-section, 
.pros-cons-section, 
.content-section {
    background: rgba(13, 16, 45, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

[data-theme="light"] .info-section,
[data-theme="light"] .features-section,
[data-theme="light"] .description-section,
[data-theme="light"] .pros-cons-section,
[data-theme="light"] .content-section {
    background: #ffffff;
    border: 1px solid rgba(0, 120, 255, 0.1);
    box-shadow: 0 4px 15px rgba(0, 120, 255, 0.05);
}

.info-section h2, 
.features-section h2, 
.description-section h2, 
.pros-cons-section h2, 
.content-section h2 {
    color: var(--accent-color);
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.info-section h2::after,
.features-section h2::after,
.description-section h2::after,
.pros-cons-section h2::after,
.content-section h2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: linear-gradient(90deg, var(--accent-color), transparent);
    border-radius: 2px;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.info-item {
    background: rgba(0, 0, 0, 0.3);
    padding: 1.5rem;
    border-radius: 8px;
    text-align: center;
}

[data-theme="light"] .info-item {
    background: rgba(240, 247, 255, 0.5);
}

.info-item h3 {
    color: var(--accent-color);
    font-weight: 500;
    margin-bottom: 1rem;
}

.features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.feature-item {
    background: rgba(0, 0, 0, 0.3);
    padding: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-family: var(--font-primary);
}

[data-theme="light"] .feature-item {
    background: rgba(240, 247, 255, 0.5);
    color: #2c3e50;
}

.check {
    color: var(--accent-color);
    font-weight: bold;
}

.pros-cons-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.pros, .cons {
    background: rgba(255, 255, 255, 0.03);
    padding: 1.5rem;
    border-radius: 8px;
}

.pros h3, .cons h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-family: var(--font-primary);
    font-weight: 600;
}

.pros ul, .cons ul {
    list-style: none;
    padding: 0;
    font-family: var(--font-primary);
}

.pros li, .cons li {
    margin: 1rem 0;
    padding-right: 1.8rem;
    position: relative;
    line-height: 1.6;
}

.full-content {
    font-family: var(--font-primary);
    line-height: 1.8;
    color: var(--text-primary);
}

@media (max-width: 768px) {
    .pros-cons-section {
        grid-template-columns: 1fr;
    }
    
    .section-title {
        font-size: 1.3rem;
    }
}

.company-detail {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2rem;
}

.company-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.company-title {
    font-size: 2rem;
    color: #fff;
    margin: 0;
}

.company-meta {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.1rem;
}

.company-description {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.8;
    margin-bottom: 2rem;
}

/* تنسيق الأقسام */
.detail-section {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
}

.section-title {
    text-align: center;
    color: #00aaff;
    font-size: 1.5rem;
    font-family: var(--font-primary);
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 1rem;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: linear-gradient(90deg, #0088ff, #00b7ff);
    border-radius: 3px;
}

/* تنسيق القوائم */
.features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin: 0;
    padding: 0;
    list-style: none;
}

.features-list li {
    color: rgba(255, 255, 255, 0.8);
    padding: 0.8rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.features-list li::before {
    content: '✓';
    color: #4CAF50;
}

/* تنسيق معلومات التداول */
.trading-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
    margin-top: 1rem;
}

.info-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
}

.info-label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.info-value {
    color: #00aaff;
    font-size: 1.2rem;
    font-weight: 600;
}

/* تنسيق التقييم */
.rating-section {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
}

.rating-stars {
    color: #ffd700;
    font-size: 1.5rem;
}

/* تحسينات للتجاوب */
@media (max-width: 768px) {
    .company-header {
        flex-direction: column;
        gap: 1rem;
    }

    .company-title {
        font-size: 1.5rem;
    }

    .trading-info {
        grid-template-columns: 1fr;
    }
} 